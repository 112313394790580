import request from '@utils/request';
// import qs from 'qs'

/**
 * Home-获取列表数据
 * url:'/xdsp/genToDoNumQuery'
 * method:GET
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 *   query_flag: '' //  01授信 02用信 03其他
 * }
 * @returns {*}
 */
export const getquestionDocList = (params = {}) => {
  return request.get('questionDoc/getListOfPage', {
    params
  });
};
/*
*查询热点问题
*/
export const getquestionDocTopList = (params = {}) => {
  return request.get('questionAndAnswer/getList', {
    params
  });
};
/*
*查询所有问题分类
*/
export const getquestionDocTypeList = (params = {}) => {
  return request.get('questionAndAnswerCategory/getList', {
    params
  });
};
export const getquestionDetail = (params = {}) => {
  return request.get('questionAndAnswer/getById', {
    params
  });
};
export const getquestionCategory = (params = {}) => {
  return request.get('questionAndAnswerCategory/getList', {
    params
  });
};

/*
*查询分类下问题列表
*/
export const getquestionCategoryList = (params = {}) => {
  return request.get('questionAndAnswer/getListOfPage', {
    params
  });
};