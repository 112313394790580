import "core-js/modules/es.array.push.js";
import common from '@mixins/common';
import { getquestionDocTypeList } from '@api/directions';
export default {
  name: 'DirectionsSystem',
  mixins: [common],
  data() {
    return {
      headerTitle: '全部问题',
      shouceList: []
    };
  },
  props: {},
  methods: {
    async handleCallback({
      isError,
      errorMessage = undefined
    }) {
      // 回调处理
      if (isError) {
        this.$toast(errorMessage || '获取失败!');
        /* if (errorMessage && errorMessage.includes('timeout')) {
          // 判断是否是网络请求超时
          this.isRefreshPage = true
          this.noDataMessage = '网络请求超时'
        } else if (errorMessage && errorMessage.includes('502')) {
          // 判断是否是服务器错误
          this.isRefreshPage = true
          this.noDataMessage = '服务器错误，502错误'
        } */
      }
    },

    async getDetailData({
      isShowLoading
    } = {
      isShowLoading: true
    }) {
      // 获取详情数据
      try {
        this.isLoading = true;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', true);
        let id = this.$route.query.id;
        // console.log(this.$route.query.id, '我是傻逼');
        // console.log(id);
        let response = await getquestionDocTypeList();
        let result = response.data;
        // console.log(result)
        if (`${result.code}` === '10000') {
          // 请求成功
          let resultData = result.data || [];
          this.shouceList = resultData;
          await this.handleCallback({
            isError: false
          });
        } else {
          // 请求失败
          await this.handleCallback({
            isError: true,
            errorMessage: result.msg || '获取列表失败!'
          });
        }
      } catch (error) {
        // console.log(error)
        await this.handleCallback({
          isError: true,
          errorMessage: error.message || '获取列表失败!'
        });
      } finally {
        this.isLoading = false;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', false);
      }
    },
    async initData() {
      await this.getDetailData();
    },
    // 进入详情
    goDetail(data) {
      let {
        id,
        name
      } = data;
      this.$router.push({
        name: 'DirectionsCategory',
        query: {
          id,
          name
        }
      });
    }
    // 
    // 
  },

  filters: {},
  // 
  created() {},
  mounted() {
    this.initData();
  },
  beforeRouteEnter(to, from, next) {
    // console.log(to)
    // console.log(from)
    next();
  },
  beforeRouteUpdate(to, from, next) {
    // console.log(to)
    // console.log(from)
    next();
  }
};